.page {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.page .wrapper {
  overflow: auto;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
}
